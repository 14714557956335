import { FOR_ONE_DAY, FOR_ONE_WEEK, timestampToTime } from './timestampToTime'

export const getLessonPeriod = ({
  boundaryTime,
  date,
}: {
  boundaryTime: number
  date?: number
}) => {
  const now = date ? new Date(date) : new Date()
  let baseTime = now.getTime()

  // 通常の処理: 20時以降は翌日開始、それ以外は当日開始
  if (now.getHours() >= boundaryTime) {
    baseTime += FOR_ONE_DAY
  }

  return {
    start_date: timestampToTime(baseTime),
    end_date: timestampToTime(baseTime + FOR_ONE_WEEK),
  }
}
