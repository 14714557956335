import * as React from 'react'
import Form from '../Form'
import {
  ReservationStepHeader,
  ReservationStepBody,
} from '../ReservationStepHeader'
import { ReservationNote } from '../ReservationNote'

import styles from './index.module.scss'
import { TrialLessonIndexView } from '../../types/trialLessons'
import { LessonTypeId } from '../LessonListContent'
import { CurrentUser } from '../../types/currentUser'

type Props = {
  stepText?: string
  id?: string
  jwtToken?: string
  selectedLesson: TrialLessonIndexView
  currentUser?: CurrentUser
  selectedLessonTypeId: LessonTypeId | null
  isRescheduled?: boolean
  reservationId?: string
  isMulticreator?: boolean
}

export const UserFormPane = ({
  stepText = 'STEP2',
  id = 'step2',
  jwtToken = '',
  selectedLesson,
  currentUser,
  selectedLessonTypeId,
  isRescheduled,
  reservationId,
  isMulticreator,
}: Props) => (
  <div id="user-form-pane">
    <ReservationStepHeader id={id} stepText={stepText}>
      お客様の情報をご入力ください
    </ReservationStepHeader>
    <ReservationStepBody>
      <div className={styles.formWrapper}>
        <Form
          jwtToken={jwtToken}
          selectedLesson={selectedLesson}
          currentUser={currentUser}
          selectedLessonTypeId={selectedLessonTypeId}
          isRescheduled={isRescheduled}
          reservationId={reservationId}
          isMulticreator={isMulticreator}
        />
        <ReservationNote />
      </div>
    </ReservationStepBody>
  </div>
)
