import dayjs from 'dayjs'
import TagManager from 'react-gtm-module'
import { TrialLessonIndexView } from '../types/trialLessons'
import { PopupType } from '../components/LinePopup'
import { createHash } from 'crypto'

// For Google Analytics
declare const window: any

export const GtagUtil = {
  reportTrialLessonReservationCompletion(
    userId: string,
    lesson: TrialLessonIndexView,
    reservationId: string,
    currentUserEmail: string | null,
    currentUserPhoneNumber: string,
    currentUserBirthday: string,
    intention_level?: number
  ) {
    const eventLabel = dayjs(lesson.starts_at).format('YYYYMMDD')
    const tagManagerArgs = {
      dataLayer: { reservation_id: reservationId },
    }
    TagManager.dataLayer(tagManagerArgs)
    if (this.hasGTag()) {
      window.dataLayer.push({
        event:
          lesson.target === 'expert'
            ? 'reservation_complete_expert'
            : 'reservation_complete',
        event_category: 'trial',
        event_label: eventLabel,
        user_id: userId,
        lesson1: lesson.lesson1,
        trial_lesson_id: lesson.id,
        user_email: currentUserEmail,
        intention_level: intention_level,
        action_source: 'website',
        first_party_collection: true,
        // doc: https://developers.facebook.com/docs/meta-pixel/advanced/advanced-matching/?translation
        em: createHash('sha256')
          .update(currentUserEmail as string)
          .digest('hex'),
        ph: currentUserPhoneNumber,
        db: currentUserBirthday,
      })
    }
  },
  setUserForShowModalLineSync() {
    TagManager.dataLayer({})
    if (this.hasGTag()) {
      window.dataLayer.push({
        event: 'set_user_info',
      })
    }
  },
  beginCheckoutStep2Class() {
    TagManager.dataLayer({})
    if (this.hasGTag()) {
      window.dataLayer.push({
        event: 'begin_checkout_step2_class',
        event_category: 'trial',
      })
    }
  },
  beginCheckoutStep2Consultation() {
    TagManager.dataLayer({})
    if (this.hasGTag()) {
      window.dataLayer.push({
        event: 'begin_checkout_step2_consultation',
        event_category: 'trial',
      })
    }
  },
  beginCheckoutStep3Datetime() {
    TagManager.dataLayer({})
    if (this.hasGTag()) {
      window.dataLayer.push({
        event: 'begin_checkout_step3_datetime',
        event_category: 'trial',
      })
    }
  },
  beginCheckoutStep4Name() {
    TagManager.dataLayer({})
    if (this.hasGTag()) {
      window.dataLayer.push({
        event: 'begin_checkout_step4_name',
        event_category: 'trial',
      })
    }
  },
  beginCheckoutStep4Email() {
    TagManager.dataLayer({})
    if (this.hasGTag()) {
      window.dataLayer.push({
        event: 'begin_checkout_step4_email',
        event_category: 'trial',
      })
    }
  },
  beginCheckoutStep4PhoneNumber() {
    TagManager.dataLayer({})
    if (this.hasGTag()) {
      window.dataLayer.push({
        event: 'begin_checkout_step4_phone_number',
        event_category: 'trial',
      })
    }
  },
  beginCheckoutStep4Password() {
    TagManager.dataLayer({})
    if (this.hasGTag()) {
      window.dataLayer.push({
        event: 'begin_checkout_step4_password',
        event_category: 'trial',
      })
    }
  },
  beginCheckoutStep4PasswordConfirmation() {
    TagManager.dataLayer({})
    if (this.hasGTag()) {
      window.dataLayer.push({
        event: 'begin_checkout_step4_password_confirmation',
        event_category: 'trial',
      })
    }
  },
  hasGTag() {
    return typeof window !== 'undefined'
  },

  // LINE連携ポップアップ表示
  displayLinePopup(
    reservationId: string | null,
    popupType: PopupType,
    via: string
  ) {
    if (!reservationId) return
    TagManager.dataLayer({
      dataLayer: {
        event: 'line_popup_displayed',
        reservation_id: reservationId,
        campaign: popupType,
        via: via,
      },
    })
  },

  // LINE連携ポップアップクリック
  clickLinePopup(
    reservationId: string | null,
    popupType: PopupType,
    via: string
  ) {
    if (!reservationId) return
    TagManager.dataLayer({
      dataLayer: {
        event: 'line_popup_clicked',
        reservation_id: reservationId,
        campaign: popupType,
        via: via,
      },
    })
  },
}
