import * as React from 'react'
import { ISODate, ISODateTime } from '../../types/date'
import classNames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import styles from './index.module.scss'

type Props = {
  date: ISODateTime | ISODate | Dayjs
  styleName?: string
  className?: string
}

const WEEKDAY_LABELS = ['月', '火', '水', '木', '金', '土', '日']
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isoWeek)

export const FancyDate = ({ date, className }: Props) => {
  const parsedDate = dayjs(date).tz('Asia/Tokyo')
  const dateText = parsedDate.format('MM/DD')
  const weekday = parsedDate.isoWeekday()
  const weekdayText = WEEKDAY_LABELS[weekday - 1]
  const saturday = weekday === 6
  const sunday = weekday === 7
  return (
    <span className={styles.fancyDate}>
      {dateText}
      <span className={styles.weekdayLabel}>
        (
        <span
          className={classNames({
            [styles.sunday]: sunday,
            [styles.saturday]: saturday,
          })}
        >
          {weekdayText}
        </span>
        )
      </span>
    </span>
  )
}
