import * as React from 'react'
// import { LoaderRow } from '../../../atoms/layout/LoaderRow';
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import styles from './index.module.scss'
import classNames from 'classnames'
import { UpdateActionButton } from '../LessonListActionButton'
import { getLocationName } from '../../types/location'
import { isOnline, shouldShowSecondLesson } from '../../types/trialLessons'
import { LocationBadge3 } from '../LocationLabel3'
import { TrialLessonIndexView } from '../../types/trialLessons'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

export const SelectedLessonSummary2 = ({
  lesson,
  onUnselect,
}: {
  lesson: TrialLessonIndexView
  onUnselect: () => void
}) => {
  const selectedLesson = lesson //.get();
  const hasSecondTrial = shouldShowSecondLesson(selectedLesson)
  const featured = isOnline(selectedLesson) && hasSecondTrial

  // if (lesson.loading) {
  //   return <LoaderRow />;
  // }
  return (
    <>
      {selectedLesson && (
        <div
          id="selected-lesson-summary"
          className={styles.cardListItem}
          onClick={() => onUnselect()}
        >
          <div
            className={styles.label}
            id={`card-list-item-${selectedLesson.id}`}
          >
            {getLocationName(selectedLesson.location) !== 'オンライン' && (
              <LocationBadge3 location={selectedLesson.location} />
            )}
            <div
              className={classNames(styles.datetime, { featured: featured })}
            >
              <span className={styles.time}>
                {dayjs(selectedLesson.starts_at)
                  .tz('Asia/Tokyo')
                  .format('HH:mm')}
                -{' '}
                {dayjs(selectedLesson.ends_at).tz('Asia/Tokyo').format('HH:mm')}
              </span>
            </div>
          </div>
          <UpdateActionButton onClick={onUnselect} />
        </div>
      )}
    </>
  )
}
