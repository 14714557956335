import * as React from 'react'
import styles from './index.module.scss'

export const PRIVACY_POLICY_URL = 'https://she-inc.co.jp/common/policy.html'
export const AGREEMENT_URL = 'https://she-inc.co.jp/common/agreement.html'
export const SHETRIALLESSON_AGREEMENT_URL =
  'https://she-inc.co.jp/common/agreement.html?agreement_type=shetriallesson'

export const ReservationNote = () => (
  <div className={styles.reservationNote} id="reservation-note">
    ※「
    <a
      className={styles.link}
      href={PRIVACY_POLICY_URL}
      target="_blank"
      rel="noreferrer"
      id="privacy-policy-link"
    >
      プライバシーポリシー
    </a>
    」および
    <a
      className={styles.link}
      href={AGREEMENT_URL}
      target="_blank"
      rel="noreferrer"
      id="sheid-agreement-link"
    >
      利用規約集
    </a>
    の「基本利用規約」および「
    <a
      className={styles.link}
      href={SHETRIALLESSON_AGREEMENT_URL}
      target="_blank"
      rel="noreferrer"
      id="shetriallesson-agreement-link"
    >
      SHE体験レッスン個別規約
    </a>
    」をご確認頂き、同意いただけた場合のみ申込ボタンをクリックしてください。フォームにご入力いただいた情報は、弊社からのサービスのご案内に利用することがございます。
    <br />
    ※当サービスは女性向け（性自認含む）となります。男性のご参加はできかねますので、ご理解ください。
    <br />
    ※無断キャンセルやいたずらと見なされる参加が続いた場合、今後体験レッスンへの参加をお断りすることがあります。
  </div>
)
