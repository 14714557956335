export const fetchLessons = async (
  url: string,
  {
    arg,
  }: {
    arg: {
      target?: 'expert'
      lesson_type_name?: string
      start_date: string
      end_date: string
      is_affiliate?: boolean
    }
  }
) =>
  fetch(
    `${url}?${new URLSearchParams({
      ...arg,
      is_affiliate: arg.is_affiliate ? 'true' : 'false',
    }).toString()}`,
    {
      method: 'GET',
    }
  ).then(res => res.json())
